<!--
 * @Description: 预约讲师首页
 * @Author: xiawenlong
 * @Date: 2021-09-06 10:09:50
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-09-30 18:43:51
-->
<template>
  <div class="teacher-home">
    <HomeSearch />
    <HomeBanner :teachermajor="teachermajor" :banner-list="bannerList" />
    <div class="container">
      <HomeTeacherList
        v-for="(item, index) in teacherList"
        :key="item.firstLevel.categoryId"
        :data="item"
        :index="index"
        :list-show="item.listShow"
        @prev="prev"
        @next="next"
      />
    </div>
    <!-- <transition name="el-fade-in-linear">
      <div v-show="active >= 0" class="float-nav">
        <div
          v-for="(item, index) in teacherList.slice(0, 7)"
          :key="item.firstLevel.categoryId"
          :class="['nav-item', { active: active === index }]"
          @click="scrollTo(index)"
        >
          {{ item.firstLevel.categoryName }}
        </div>
      </div>
    </transition> -->
  </div>
</template>
<script>
let scrollEventTimer
import HomeSearch from './components/HomeSearch.vue'
import HomeBanner from './components/HomeBanner.vue'
import HomeTeacherList from './components/HomeTeacherList.vue'
import { teacherHomePage, teacherHomePageNext, teachermajor, teacherBanner } from '@/api/teacher'
import to from 'await-to'
export default {
  name: 'TeacherHome',
  components: {
    HomeSearch,
    HomeBanner,
    HomeTeacherList,
  },
  data() {
    return {
      active: -1,
      teacherList: [],
      listShow: true,
      teachermajor: [],
      bannerList: [],
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
    this.init()
    this.getTeacherMajor()
    this.getBanner()
  },
  destroy() {
    window.removeEventListener('scroll', this.onScroll)
    clearInterval(scrollEventTimer)
  },
  methods: {
    async getTeacherMajor() {
      const [res, err] = await to(teachermajor({}))
      if (err) return this.$message.error(err.msg)
      this.teachermajor = res.data
    },
    async getBanner() {
      const [res, err] = await to(teacherBanner({}))
      if (err) return this.$message.error(err.msg)
      this.bannerList = res.data
    },
    async init() {
      const [res, err] = await to(teacherHomePage({}))
      if (err) return this.$message.error(err.msg)
      this.teacherList = res.data
      this.teacherList.map(item => {
        this.$set(item, 'listShow', true)
      })
    },
    async prev({ data, index }) {
      const { firstLevel } = data
      data.listShow = false
      const [res, err] = await to(
        teacherHomePageNext({
          categoryId: firstLevel.categoryId,
          lastIndex: this.teacherList[index].lastIndex || 8,
          size: 8,
          upOrDown: 2,
        }),
      )
      if (err) return this.$message.error(err.msg)
      this.teacherList[index].teachers = res.data
      this.teacherList[index].lastIndex = res.extendData.lastIndex
      data.listShow = true
    },
    async next({ data, index }) {
      const { firstLevel } = data
      data.listShow = false
      const [res, err] = await to(
        teacherHomePageNext({
          categoryId: firstLevel.categoryId,
          lastIndex: this.teacherList[index].lastIndex || 9,
          size: 8,
          upOrDown: 1,
        }),
      )
      if (err) return this.$message.error(err.msg)
      this.teacherList[index].teachers = res.data
      this.teacherList[index].lastIndex = res.extendData.lastIndex
      data.listShow = true
    },
    onScroll() {
      const navContents = document.querySelectorAll('.home-teacher-list')
      const RectBottoms = []
      navContents.forEach(item => {
        RectBottoms.push(item.getBoundingClientRect().bottom)
      })
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
      let navIndex = -1
      for (let n = 0; n < RectBottoms.length; n++) {
        if (clientHeight >= RectBottoms[n]) {
          navIndex = n
        }
      }
      this.active = navIndex
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      clearInterval(scrollEventTimer)
      this.active = index
      window.removeEventListener('scroll', this.onScroll)
      const targetOffsetTop =
        document.querySelector(`.home-teacher-list:nth-child(${index + 1})`).offsetTop + 52
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const STEP = 60
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp()
      } else {
        // 往下滑
        smoothDown()
      }
      scrollEventTimer = setTimeout(() => {
        window.addEventListener('scroll', this.onScroll)
      }, 1000)
      // 定义往下滑函数
      function smoothDown() {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown)
        }
      }
      // 定义往上滑函数
      function smoothUp() {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          requestAnimationFrame(smoothUp)
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.teacher-home {
  position: relative;
  padding: 0 0 106px;
  .home-search {
    padding-top: 16px;
    background-color: #fff;
  }
  .container {
    width: 1200px;
    margin: 0 auto;
  }
  .float-nav {
    z-index: 999;
    position: fixed;
    bottom: 100px;
    left: 340px;
    width: 69px;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    .nav-item {
      overflow: hidden;
      cursor: pointer;
      display: block;
      width: 100%;
      height: 43px;
      line-height: 43px;
      border-bottom: 1px solid #e8e8e8;
      font-size: 14px;
      text-align: center;
      color: #333333;
      &:last-child {
        border: 0;
      }
      &.active {
        color: #fff;
        background: #ff6c00;
      }
    }
  }
}
</style>
