<!--
 * @Description: 首页搜索
 * @Author: xiawenlong
 * @Date: 2021-09-06 10:14:28
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-09-30 10:57:25
-->
<template>
  <div class="home-search">
    <img src="@/assets/teacher/logo.png" alt="" class="logo" />
    <div class="main">
      <div class="search-bar">
        <input
          v-model="keyword"
          placeholder="请输入讲师名称"
          type="text"
          maxlength="64"
          @keydown.enter="toSearch(keyword)"
        />
        <div class="btn" @click="toSearch(keyword)">搜 索</div>
      </div>
      <div class="search-keyword">
        热门搜索：<span v-for="(item, index) in keywords" :key="index" @click="toSearch(item)">{{
          item
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HomeSearch',
  data() {
    return {
      keyword: '',
      keywords: ['汪军民', '杨久成', '王清刚', '龙勇', '侯卓'],
    }
  },
  methods: {
    toSearch(keyword) {
      this.$router.push(`/teacher/list?keyword=${keyword}`)
    },
  },
}
</script>
<style lang="scss" scoped>
.home-search {
  display: flex;
  justify-content: center;
  // align-items: center;
  padding-bottom: 15px;
  .logo {
    height: 64px;
    margin-right: 33px;
  }
  .search-bar {
    box-sizing: border-box;
    width: 482px;
    height: 40px;
    border: 1px solid #ff7b33;
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    input {
      width: 392px;
      height: 100%;
      outline: 0;
      border: 0;
      padding: 10px;
      box-sizing: border-box;
      color: #333333;
    }
    .btn {
      box-sizing: border-box;
      cursor: pointer;
      width: 90px;
      height: 40px;
      background: #ff8644;
      border: 1px solid #ff8644;
      font-size: 14px;
      text-align: center;
      color: #ffffff;
      line-height: 40px;
    }
  }
  .search-keyword {
    width: 482px;
    margin: 0 auto;
    color: #606266;
    line-height: 12px;
    span {
      cursor: pointer;
      margin-right: 18px;
    }
  }
}
</style>
