<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2021-09-06 11:57:22
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-09-29 14:15:07
-->
<template>
  <div class="home-banner">
    <div class="container">
      <div class="nav">
        <div v-for="(item, index) in teachermajor" :key="index" class="nav-item">
          <span class="wrap" @click="toList({ firstMajors: item.categoryId })">
            <span>{{ item.name }}</span> <i class="el-icon-arrow-right"></i>
          </span>
          <div class="hover-list">
            <div class="title">细分领域</div>
            <div
              v-for="category in item.children"
              :key="category.categoryId"
              class="item"
              @click="toList({ firstMajors: item.categoryId, secondMajors: category.categoryId })"
            >
              {{ category.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-carousel height="360px">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <img :title="item.name" :src="item.imgUrl" alt="" @click="toLink(item.linkUrl)" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: 'HomeBanner',
  props: {
    teachermajor: {
      type: Array,
      default: () => [],
    },
    bannerList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  methods: {
    toLink(url) {
      window.location.href = url
    },
    toList({ firstMajors, secondMajors }) {
      let query =
        (firstMajors ? 'firstMajors=' + firstMajors : '') +
        (secondMajors ? (firstMajors ? '&' : '') + 'secondMajors=' + secondMajors : '')
      this.$router.push(`/teacher/list?${query}`)
    },
  },
}
</script>
<style lang="scss" scoped>
.home-banner {
  .container {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    .nav {
      z-index: 30;
      position: absolute;
      left: 0;
      top: 0;
      width: 170px;
      height: 360px;
      background: rgba($color: #000000, $alpha: 0.4);
      overflow: hidden;
      &:hover {
        height: auto;
        overflow: initial;
      }
      .nav-item {
        cursor: pointer;
        box-sizing: border-box;
        width: 100%;
        padding-left: 28px;
        font-size: 14px;
        .wrap {
          height: 60px;
          display: flex;
          align-items: center;
          width: 100%;
          span {
            flex: 1;
          }
          i {
            margin-right: 39px;
          }
          span,
          i {
            color: #fff;
          }
        }
        &:hover {
          span,
          i {
            color: #4a70df;
          }
          background: #f8f8f8;
          border-left: 4px solid #4a70df;
          padding-left: 24px;
          .hover-list {
            display: block;
          }
        }
        .hover-list {
          box-sizing: border-box;
          display: none;
          position: absolute;
          padding: 20px 30px;
          width: 480px;
          height: 100%;
          background: #fff;
          z-index: 9999;
          left: 170px;
          top: 0;
          font-size: 16px;
          overflow-y: auto;
          .title {
            font-size: 16px;
            color: #4a70df;
            margin: 0 20px 20px;
          }
          .item {
            font-size: 14px;
            color: #606266;
            float: left;
            padding: 20px;
            &:hover {
              color: #4a70df;
            }
          }
        }
      }
    }
  }
  .el-carousel__item {
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-align: center;
    img {
      height: 100%;
      width: 100%;
      object-fit: none;
    }
  }
}
</style>
