<!--
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-09-06 14:27:51
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-09-30 15:19:09
-->
<template>
  <div class="home-teacher-list">
    <div class="header">
      <div class="title" @click="toList({ firstMajors: data.firstLevel.categoryId })">
        {{ data.firstLevel.categoryName }}
      </div>
      <div class="nav">
        <div
          v-for="item in data.secondLevels.slice(0, 5)"
          :key="item.categoryId"
          class="nav-item"
          @click="
            toList({ firstMajors: data.firstLevel.categoryId, secondMajors: item.categoryId })
          "
        >
          {{ item.categoryName }}
        </div>
      </div>
    </div>
    <div class="container">
      <div
        v-if="data.recommendTeacher"
        class="left"
        @click="toTeacherDetail(data.recommendTeacher)"
      >
        <!-- <img class="cover" :src="data.recommendTeacher.teacherPics[0]" /> -->
        <div
          class="cover"
          :style="
            `background-image:url(${data.recommendTeacher.teacherPics &&
              data.recommendTeacher.teacherPics[0]})`
          "
        ></div>
        <div class="name">{{ data.recommendTeacher.teacherName }}</div>
        <!-- <div v-if="data.recommendTeacher.feature" class="level">
          {{ data.recommendTeacher.feature }}
        </div> -->
        <div class="skill">
          <span v-for="tag in data.recommendTeacher.teacherTags" :key="tag.key">{{
            tag.value
          }}</span>
        </div>
      </div>
      <div v-if="data.teachers.length" class="main">
        <transition name="slide-fade">
          <div v-show="listShow" class="transition-box">
            <div
              v-for="teacher in data.teachers"
              :key="teacher.teacherId"
              class="teacher-item"
              @click="toTeacherDetail(teacher)"
            >
              <!-- <img class="cover" :src="teacher && teacher.teacherPics[0]" /> -->
              <div
                class="cover"
                :style="`background-image:url(${teacher.teacherPics && teacher.teacherPics[0]})`"
              ></div>
              <div class="name">{{ teacher.teacherName }}</div>
              <!-- <div class="courseNum">{{ teacher.productCourseCount }}门课</div> -->
              <!-- <div v-if="teacher.feature" class="level">{{ teacher.feature }}</div> -->

              <div class="skill">
                <span v-for="tag in teacher.teacherTags" :key="tag.key">{{ tag.value }}</span>
              </div>
            </div>
          </div>
        </transition>
        <div class="pageTool">
          <div class="leftBtn" @click="prev">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="rightBtn" @click="next">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HomeTeacherList',
  props: {
    data: {
      type: Object,
      default: () => ({
        recommendTeacher: {},
      }),
    },
    index: {
      type: Number,
      default: 0,
    },
    listShow: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    prev() {
      this.$emit('prev', { data: this.data, index: this.index })
    },
    next() {
      this.$emit('next', { data: this.data, index: this.index })
    },
    toList({ firstMajors, secondMajors }) {
      let query =
        (firstMajors ? 'firstMajors=' + firstMajors : '') +
        (secondMajors ? (firstMajors ? '&' : '') + 'secondMajors=' + secondMajors : '')
      this.$router.push(`/teacher/list?${query}`)
    },
    toTeacherDetail(teacher) {
      this.$router.push(
        `/teacher/detail?id=${teacher.teacherId}&organizationId=${teacher.organizationId}`,
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.home-teacher-list {
  &:nth-of-type(2n-1) {
    .left {
      background: url('../../../../assets/teacher/home_bg_1.png');
    }
  }
  &:nth-of-type(2n) {
    .left {
      background: url('../../../../assets/teacher/home_bg_2.png');
    }
  }
  &:nth-of-type(3n) {
    .left {
      background: url('../../../../assets/teacher/home_bg_3.png');
    }
  }
  &:nth-of-type(4n) {
    .left {
      background: url('../../../../assets/teacher/home_bg_4.png');
    }
  }
  .header {
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      cursor: pointer;
      padding-left: 10px;
      font-size: 24px;
      color: #333333;
    }
    .nav {
      display: flex;
      align-items: center;
      .nav-item {
        cursor: pointer;
        height: 36px;
        line-height: 36px;
        background: #ececec;
        border-radius: 18px;
        padding: 0 28px;
        font-size: 14px;
        text-align: center;
        color: #333333;
        margin-left: 20px;
        &:hover {
          background-color: #ff6c00;
          color: #fff;
        }
      }
    }
  }
  .container {
    display: flex;
    height: 445px;
    .left {
      cursor: pointer;
      // width: 180px;
      // height: 401px;
      width: 220px;
      height: 445px;
      margin-right: 8px;
      padding: 40px 0;
      box-sizing: border-box;
      text-align: center;
      .cover {
        display: block;
        // width: 148px;
        // height: 175px;
        width: 188px;
        height: 235px;
        margin: 0 auto;
        margin-bottom: 15px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
      }
      .name {
        height: 16px;
        line-height: 16px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        margin-bottom: 14px;
      }
      .level {
        display: inline-block;
        padding: 0 14px;
        height: 20px;
        background: #f8ece2;
        border-radius: 2px;
        margin-bottom: 20px;
        text-align: center;
        font-size: 12px;
        color: #ff7b33;
      }
      .skill {
        text-align: left;
        padding-left: 16px;
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 71px;
          display: inline-block;
          height: 20px;
          line-height: 20px;
          background: rgba(255, 255, 255, 0.35);
          border-radius: 2px;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          padding: 0 6px;
          margin-bottom: 9px;
          margin-right: 4px;
        }
      }
    }
    .main {
      flex: 1;
      box-sizing: border-box;
      // height: 401px;
      padding-left: 16px;
      position: relative;
      .pageTool {
        display: none;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 0;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        .leftBtn,
        .rightBtn {
          cursor: pointer;
          width: 40px;
          height: 40px;
          background-color: rgba(0, 0, 0, 0.35);
          border-radius: 50%;
          text-align: center;
          line-height: 40px;
          color: #fff;
          font-size: 30px;
        }
        .rightBtn {
          margin-right: -6px;
        }
      }
      &:hover {
        .pageTool {
          display: flex;
        }
      }
      .teacher-item {
        vertical-align: bottom;
        display: inline-block;
        cursor: pointer;
        // width: 231px;
        // height: 220px;
        width: 220px;
        height: 215px;
        background: #ffffff;
        padding: 8px;
        box-sizing: border-box;
        margin-right: 24px;
        margin-bottom: 15px;
        text-align: center;
        overflow: hidden;
        &:nth-of-type(4n) {
          margin-right: 0;
        }
        .cover {
          width: 100%;
          height: 119px;
          display: block;
          margin-bottom: 5px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
        }
        .name {
          font-size: 16px;
          height: 16px;
          line-height: 16px;
          font-weight: 500;
          text-align: center;
          color: #333333;
          margin-bottom: 8px;
        }
        .courseNum {
          height: 14px;
          font-size: 14px;
          line-height: 14px;
          font-weight: 400;
          text-align: center;
          color: #909399;
          margin-bottom: 8px;
        }
        .level {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 180px;
          display: inline-block;
          padding: 0 14px;
          height: 20px;
          line-height: 20px;
          background: #f8ece2;
          border-radius: 2px;
          font-size: 12px;
          text-align: center;
          color: #ff7b33;
          margin-bottom: 7px;
        }
        .skill {
          // text-align: left;
          padding-left: 4px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 71px;
            display: inline-block;
            height: 22px;
            line-height: 22px;
            border-radius: 2px;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            padding: 0 8px;
            margin-bottom: 7px;
            margin-right: 10px;
            &:nth-of-type(2n-1) {
              color: #5ad2ff;
              background: #e6f5fd;
            }
            &:nth-of-type(2n) {
              color: #ff7b33;
              background: #f8ece2;
            }
            &:nth-of-type(3n) {
              color: #b495ff;
              background: #f2effe;
            }
            &:nth-of-type(4n) {
              color: #a19ea2;
              background: #f3f0f3;
            }
          }
        }
      }
    }
  }
  .slide-fade-enter-active {
    transition: all 0.1s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
}
</style>
